<template>
  <b-sidebar
    id="sidebar-activity"
    :visible="isShowSidebar"
    aria-labelledby="sidebar-activity"
    shadow
    right
    :lazy="true"
    backdrop
    :no-enforce-focus="true"
    width="85vw"
    @hidden="hide"
  >
    <template #header>
      <b-row class="header-filter no-gutters">
        <b-col class="text-filter"> Add Ticket</b-col>
      </b-row>
    </template>
    <div class="p-3">
      <FormInputList
        ref="form"
        :items="formDetail.fields || []"
        :depth="0"
        :errors="$v.formDetail.fields"
        :is-customer="true"
        :pluginEmail="formDetail.pluginEmail"
        :currentPage="currentPage"
      />
      <div class="pagination-wrapper" v-if="hasPage">
        <div>{{ currentPage }} / {{ totalPage }}</div>
        <div class="pagination-action">
          <b-button
            class="btn main-color btn-main w-100px"
            :disabled="currentPage == 1"
            @click="prevPage"
          >
            Previous</b-button
          >

          <b-button
            class="btn main-color btn-main w-100px"
            :disabled="currentPage == totalPage"
            @click="nextPage"
          >
            Next</b-button
          >
        </div>
      </div>
    </div>
    <template #footer>
      <div
        class="d-flex justify-content-between shadow align-items-center footer"
      >
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="sendForm"
            block
            variant="light"
            id="handle-search-filter"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import FormInputList from "@/components/ticket/activity/FormInputList";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  components: {
    FormInputList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      currentPage: 1,
      formDetail: {
        id: 1,
        name: "",
        url_key: "",
        valid_form: null,
        valid_to: null,
        description: "",
        active: 1,
        is_default: 0,
        fields: [],
      },
    };
  },

  computed: {
    formStatus() {
      return this.formDetail.fields.find((el) => el.ticket_field_id == 5).value;
    },
    hasPage() {
      return this.formDetail.fields.some((el) => el.field_type_id == 14);
    },
    totalPage() {
      return this.formDetail.fields[this.formDetail.fields.length - 1].page;
    },
  },
  validations() {
    return {
      formDetail: {
        fields: {
          $each: {
            value: {
              required: requiredIf(function (item) {
                const isSubmit = Boolean(item.is_require_submit);
                const isClose =
                  this.formStatus == 4 && Boolean(item.is_require_close);
                return isSubmit || isClose;
              }),
            },
            field_dependent: {
              $each: {
                value: {
                  required: requiredIf(function (item) {
                    const isSubmit =
                      Boolean(item.is_require_submit) && item.isActive;
                    const isClose =
                      this.formStatus == 4 &&
                      Boolean(item.is_require_close) &&
                      item.isActive;
                    return isSubmit || isClose;
                  }),
                },
                field_dependent: {
                  $each: {
                    value: {
                      required: requiredIf(function (item) {
                        const isSubmit =
                          Boolean(item.is_require_submit) && item.isActive;
                        const isClose =
                          this.formStatus == 4 &&
                          Boolean(item.is_require_close) &&
                          item.isActive;
                        return isSubmit || isClose;
                      }),
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  created() {},
  methods: {
    nextPage() {
      this.currentPage += 1;
      this.$forceUpdate();
    },
    prevPage() {
      this.currentPage -= 1;
      this.$forceUpdate();
    },
    show() {
      this.getFormDetail();
      this.isShowSidebar = true;
    },
    hide() {
      this.$v.formDetail.$reset();
      this.isShowSidebar = false;
    },
    async getFormDetail() {
      const { data } = await this.axios(`/ticket/form/1`);
      if (data.result === 1) {
        data.detail.fields.forEach((element) => {
          element.field_dependent = this.mapFieldFromDepth(
            element.field_dependent,
            element.id
          );
        });

        if (this.user.email) {
          this.$refs.form.isUserHasEmail = true;
        } else {
          this.$refs.form.isUserHasEmail = false;
        }
        this.formDetail = data.detail;
        this.formDetail.fields = this.mappingValue(this.formDetail.fields);
      }
    },
    mapFieldFromDepth(fields, parentId = null) {
      if (!fields?.length) return [];
      return fields
        .filter((el) => el.parent_id === parentId)
        .map((el) => {
          return {
            ...el,
            field_dependent: this.mapFieldFromDepth(
              fields,
              el.ticket_form_field_id
            ),
          };
        });
    },
    mappingValue(items, page = 1) {
      if (!items.length) return;
      return items.map((el, index) => {
        if (items[index - 1] && items[index - 1].field_type_id == 14) page += 1;

        if (el.field_dependent?.length)
          el.field_dependent = this.mappingValue(el.field_dependent, page);

        let value = null;

        let displayValue = "";
        if (el.ticket_field_id == 1) {
          displayValue = [
            this.user.first_name_th,
            this.user.last_name_th,
            this.user.telephone,
          ]
            .filter((el) => el)
            .join(", ");
          value = this.user.user_guid;
        } else if ([2, 3, 4].includes(el.field_type_id)) {
          const defaultValue = el.field_choice
            .filter((val) => Boolean(val.isDefault))
            .map((val) => {
              return el.field_type_id == 3 ? { ...val } : val.id;
            });
          value = [2, 3].includes(el.field_type_id)
            ? defaultValue || []
            : defaultValue[0] || null;
        }

        return { ...el, value, displayValue, isActive: false, page };
      });
    },

    flatFields(data) {
      return data
        .reduce((acc, x) => {
          acc = acc.concat(x);
          if (x.field_dependent) {
            acc = acc.concat(this.flatFields(x.field_dependent));
            x.field_dependent = [];
          }
          return acc;
        }, [])
        .map((el) => {
          let value = el.value;
          if (el.field_type_id == 2)
            value = el.value.map((item) => item).join(",");
          else if (el.field_type_id == 3)
            value = el.value.map((item) => item.id).join(",");
          else if (el.ticket_field_id == 6) value = el.value ? el.value.id : "";
          return {
            id: 0,
            ticket_form_field_id: el.ticket_form_field_id || el.id,
            ticket_field_id: el.ticket_field_id,
            value,
            is_default_field: el.is_default_field,
            field_dependent: el.field_dependent?.length
              ? this.flatFields(el.field_dependent)
              : [],
          };
        });
    },

    async sendForm() {
      this.$v.formDetail.$touch();
      if (this.$v.formDetail.$error) {
        return;
      }
      this.$bus.$emit("showLoading");

      const field_answer = this.flatFields(this.formDetail.fields);
      let file = this.$refs.form.fileAttach.map((el) => {
        return { fileName: el.name, path: el.path };
      });
      const payload = {
        id: 0,
        ticket_form_id: 1,
        field_answer,
        is_send_mail_customer: this.$refs.form.is_send_mail_customer || false,
        attach_file: file.length == 0 ? null : file,
      };

      const { data } = await this.axios.post(`/ticket`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.hide();
          this.$emit("afterSubmit");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },

    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: unset;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0.5rem;
}
.text-filter {
  font-weight: 600;
}

.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .btn-main,
.btn-mains button {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.footer {
  ::v-deep .rounded-pill {
    border-radius: 0px !important;
  }
  ::v-deep .btn-main,
  .btn-mains button {
    width: 100% !important;
  }
}

.text-under {
  text-decoration: underline;
}

input.search-bar::placeholder {
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 0.5rem !important;
}

.dependent-form {
  background-color: var(--secondary-color);
  padding: 16px;
}

.question-card-title {
  display: flex;
  gap: 4px;
  align-items: center;
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
}

.text-light-grey {
  color: #9a9a9a;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-action {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.w-100px {
  width: 101px !important;
  min-width: 0 !important;
}
</style>

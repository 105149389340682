<template>
  <div>
    <label for="ticketType" class="mb-1" v-if="!hideLabel">{{ title }}</label>
    <multiselect
      id="ticketType"
      v-model="selectedValue"
      trackBy="id"
      label="name"
      placeholder="Select type to search"
      open-direction="bottom"
      :class="className"
      :options="options"
      :multiple="true"
      :searchable="searchable"
      :internal-search="true"
      :max-height="600"
      :limit="10"
      :show-no-results="searchable"
      :hide-selected="false"
      :show-labels="false"
      @search-change="asyncFind"
      @select="onChange($event, 1)"
      @remove="onChange($event, 0)"
    >
      <template #noResult>
        <span>No matching records found</span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    title: {
      required: false,
      default: "Type",
    },
    hideLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    className: {
      required: false,
    },
    searchable: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      options: [],
      filter: {
        search: "",
      },
      isLoading: false,
      selectedValue: this.value,
    };
  },
  async mounted() {
    this.getList();
  },
  watch: {
    async value(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    getList: async function () {
      this.isBusy = true;

      const { data } = await this.axios.get(`/ticket/filter/6`);
      if (data.result == 1) {
        this.options = data.detail;
      }
      this.isBusy = false;
    },

    asyncFind(query) {
      this.filter.search = query;
      this.getList();
    },
    onChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="bg-white p-3">
    <b-table
      ref="tablecustom"
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <!-- <template v-slot:cell(name)="data">
        <b-row
          ><b-col md="6" class="text-left">{{ data.item.name }}</b-col>
          <b-col md="6" class="text-left">{{ data.item.prefix }}</b-col></b-row
        >
      </template> -->
      <template v-slot:cell(start_date)="data">
        <b-row>
          <b-col v-if="data.item.expired_date">
            {{ data.item.created_time | moment("DD/MM/YYYY") }}
            <span v-if="data.item.end_date">
              -
              {{ data.item.expired_date | moment("DD/MM/YYYY") }}
            </span>
          </b-col>
          <b-col v-else>
            {{ data.item.start_date | moment("DD/MM/YYYY") }}
            <span v-if="data.item.end_date">
              -
              {{
                data.item.expired_date == null
                  ? data.item.end_date
                  : data.item.expired_date | moment("DD/MM/YYYY")
              }}</span
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:cell(used_date)="data">
        <span class="text-center" v-if="data.item.used_date"
          >{{ data.item.used_date | moment("DD/MM/YYYY") }}
          <span class="datetime"
            >({{ data.item.used_date | moment("HH:mm:ss") }})</span
          >
        </span>
        <span class="text-center" v-else>- </span>
      </template>
      <template v-slot:cell(invoice_no)="data">
        <span class="text-center">{{ data.item.invoice_no || "-" }} </span>
      </template>
      <template v-slot:cell(action)="{ item }">
        <b-button
          v-if="
            item.status_name == 'ยังไม่ได้ใช้งาน' ||
            item.status_name == 'ยังไม่ได้ใช้งาน (หมดอายุ)'
          "
          variant="custom-outline-primary"
          @click="redeemCoupon(item)"
          >ใช้คูปอง
        </b-button>
      </template>
      <template v-slot:cell(created_time)="data">
        <span class="text-center" v-if="data.item.created_time"
          >{{ data.item.created_time | moment("DD/MM/YYYY") }}
          <span class="datetime"
            >({{ data.item.created_time | moment("HH:mm:ss") }})</span
          >
        </span>
        <span class="text-center" v-else> - </span>
      </template>
      <template v-slot:cell(status_name)="data">
        <b-row
          ><b-col>
            <span :style="'color:' + data.item.status_color">{{
              data.item.status_name
            }}</span>
          </b-col></b-row
        >
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
    <b-modal hide-footer v-model="modalShow" title="Redeem Coupon">
      <InputSelect
        title="Branch"
        name="Branch"
        class="mt-2"
        v-model="branchSelect"
        v-bind:options="branchList"
        valueField="id"
        textField="name"
        :v="$v.branchSelect"
        :isValidate="$v.branchSelect.$error"
      >
        <template v-slot:option-first>
          <b-form-select-option :value="null" disabled
            >-- Select Branch --
          </b-form-select-option>
        </template>
      </InputSelect>
      <b-row>
        <b-col sm="6">
          <b-button class="modal-btn cancel-btn" @click="modalShow = false">
            Cancel
          </b-button>
        </b-col>
        <b-col sm="6" class="d-flex justify-content-end">
          <b-button class="modal-btn submit-btn" @click="redeem">
            Confirm
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    tab: {
      required: true,
      type: Number,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
  },
  validations() {
    return { branchSelect: { required } };
  },
  data() {
    return {
      toShow: this.showingTo,
      modalShow: false,
      branchList: [],
      branchSelect: null,
      coupon: null,
    };
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    async redeemCoupon(item) {
      this.branchSelect = null;
      const resp = await this.axios(
        `/Coupon/coupon_detail/${item.promotion_id}/${this.$route.params.id}`
      );

      this.coupon = resp.data.detail.detail.detail;

      await this.$store.dispatch(
        "getBranch",
        resp.data.detail.detail.detail.branch_group_id
      );
      let branch = [];
      for (const a of this.$store.state.coupon.branchList.detail) {
        for (const b of a.branch) {
          if (b.is_check) {
            branch.push(b);
          }
        }
      }
      this.branchList = branch;
      this.$v.$reset();
      this.modalShow = true;
    },
    async redeem() {
      this.$v.$touch();

      if (this.$v.$error) return;
      this.$bus.$emit("showLoading");
      const resp = await this.axios.post(
        `/Coupon/redeem_coupon/${this.branchSelect}/${this.$route.params.id}`,
        this.coupon
      );

      this.$bus.$emit("hideLoading");
      if (resp.data.result == 0) this.errorAlert(resp.data.message);
      else {
        this.$emit("page", this.filter);
        this.successAlert();
        this.modalShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #0098f7 !important;
}
.text-invoice_no {
  color: #0098f7;
  text-decoration: underline;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: red;
}
.bg-log-type {
  opacity: 0.5;
  border-radius: 50px;
  padding: 0 10px;
}
.log-type-odd {
  background-color: #d79b9b;
  color: red;
}
.log-type-even {
  background-color: #9bd79b;
  color: green;
}
.datetime {
  color: #919191;
}
.bg-use {
  background-color: lightgray;
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
}
</style>
